<template>
  <v-stepper
    class="order-steps mb-4 mb-sm-0"
    v-if="!is_empty_obj(orderDetails)"
    elevation="0"
    :value="getCurrentStatus()"
    alt-labels
  >
    <v-stepper-header>
      <template v-for="(step, i) in steps">
        <v-stepper-step
          :key="`${i}-step`"
          :step="i + 1"
          :complete="getStepStatus(step.status)"
          complete-icon="la-check"
          error-icon="la-times"
          :rules="[getCancelStatus]"
          color="#85EEDF"
        >
          {{ getLevel(step.level) }}
        </v-stepper-step>
        <v-divider
          v-if="i !== steps.length - 1"
          :key="i"
          :class="[{ complete: getStepStatus(step.status) }]"
        ></v-divider>
      </template>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
export default {
  props: {
    orderDetails: { type: Object, default: () => {} }
  },
  data: () => ({}),
  computed: {
    steps() {
      return [
        {
          level: this.$i18n.t('order_placed'),
          status: 'pending'
        },
        {
          level: this.$i18n.t('confirmed'),
          status: 'confirmed'
        },
        // {
        //   level: this.$i18n.t('processed'),
        //   status: 'picked_up'
        // },
        {
          level: this.$i18n.t('on_the_way'),
          status: 'on_the_way'
        },
        {
          level: this.$i18n.t('delivered'),
          status: 'delivered'
        }
      ]
    }
  },
  methods: {
    getLevel(level) {
      return this.orderDetails.delivery_status == 'cancelled'
        ? this.$i18n.t('cancelled')
        : level
    },
    getCancelStatus() {
      return this.orderDetails.delivery_status == 'cancelled' ? false : true
    },
    getCurrentStatus() {
      // return this.orderDetails.has_refund_request ? -1 : (
      //   this.steps.findIndex(
      //     (step) => step.status == this.orderDetails.delivery_status
      //   ) + 2
      // )
      return this.orderDetails.delivery_status === 'cancelled'
        ? -1
        : this.steps.findIndex(
            (step) => step.status == this.orderDetails.delivery_status
          ) + 2
    },
    getStepStatus(status) {
      // if (this.orderDetails.has_refund_request) {
      //   return false
      // }
      if (this.orderDetails.delivery_status === 'cancelled') {
        return false
      }

      const activeIndex = this.steps.findIndex(
        (step) => step.status == this.orderDetails.delivery_status
      )
      const currentIndex = this.steps.findIndex((step) => step.status == status)
      return currentIndex <= activeIndex
    }
  }
}
</script>

<style scoped>
::v-deep .v-divider.complete {
  border-color: #85EEDF !important;
}

::v-deep .v-stepper__step--active:not(.v-stepper__step--error) .v-stepper__step__step {
  border-color: #85EEDF !important;
}

::v-deep .v-stepper__step--active:not(.v-stepper__step--error) .v-stepper__step__step:before {
  background: #85EEDF !important;
}

::v-deep .v-stepper__label {
  color: #85EEDF !important;
}

</style>
